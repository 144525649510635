import {
  encrypt,
  decrypt,
  setCookie,
  getCookie,
  deleteCookies,
  validatePurchaseData,
} from './utils'

// rloadAmt
export interface Ipurchase {
  AdNm: string // 광고주명
  ordNo: string // 주문번호
  itemId: string // 상품ID
  itemNm: string // 상품명
  sellprc: number // 판매가격
  ordAmt: number // 주문금액
  dcAmt: number // 할인가격
  rlordAmt: number // 실주문금액
  rlordQty: number // 실주문갯수
  ordState: string // 주문상태
  ordDate: string // 주문일자
  Commission: number // 수수료
  ordUniqNo?: string // 주문고유번호
  updateDate?: string // 수정일자
  Apclid?: string // 앱클릭아이디
  ChNm?: string // 매체명
}

function getQueryParam(param: string): string | null {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}

function init(): void {
  //Common
  const gbClick = getQueryParam('gb_click')
  const gbMedia = getQueryParam('gb_media')
  const affp1 = getQueryParam('affp1')
  const affp2 = getQueryParam('affp2')
  const affp3 = getQueryParam('affp3')
  const affp4 = getQueryParam('affp4')
  const affp5 = getQueryParam('affp5')
  //애드픽 클릭키
  const adpickClick = getQueryParam('apclid')
  const originCookieValue = `${adpickClick}`
  const originEncryptedValue = encrypt(originCookieValue)


  // ✅ delete 조건: linkD 포함 OR utm_source 정확히 gb_cps
  const urlHasLinkD = window.location.href.includes('linkD')
  const utmSource = getQueryParam('utm_source')
  const shouldDeleteCookies =
    urlHasLinkD || (utmSource !== null && utmSource !== 'gb_cps')


  
  if (adpickClick && gbClick && gbMedia) {
    //adpick & gb 둘다있을경우
    const newCookieValue = `${gbMedia}_${gbClick}`
    const encryptedValue = encrypt(newCookieValue)

    //커스텀
    const affpParams: Record<string, string | null> = {
      affp1,
      affp2,
      affp3,
      affp4,
      affp5,
    }

    //필터링
    const affpEntries = Object.entries(affpParams)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${value}`)

    //삭제
    
    deleteCookies('gb_cps', 'apclid', 'affp_data')

    //새로 생성
    setCookie('gb_cps', encryptedValue, 24)
    setCookie('apclid', originEncryptedValue, 24)
    if (affpEntries.length > 0) {
      const affpCookieValue = affpEntries.join('&')
      setCookie('affp_data', affpCookieValue, 24)
    }
  } else if (adpickClick && !gbClick && !gbMedia) {
    //adpick만있는경우
    deleteCookies('gb_cps', 'apclid', 'affp_data')
    setCookie('apclid', originEncryptedValue, 24)
  } else if (shouldDeleteCookies){
    
      deleteCookies('gb_cps', 'apclid', 'affp_data')
  }
}

function getCookieValue(): string | null {
  const encryptedValue = getCookie('gb_cps')
  return encryptedValue ? decrypt(encryptedValue) : null
}
function originGetCookieValue(): string | null {
  const originEncryptedValue = getCookie('apclid')
  return originEncryptedValue ? decrypt(originEncryptedValue) : null
}

function purchase(data: Ipurchase) {
  //유효성처리
  const adpickCpsCookie = getCookie('apclid')//애드픽 히스토리있는것들만
  if (!adpickCpsCookie) {
    return
  }
  //gb_cps쿠키담기기
  const gbCpsCookie = getCookie('gb_cps')
  if (gbCpsCookie) {
    const decryptedValue = decrypt(gbCpsCookie as string)
    const firstUnderscoreIndex = decryptedValue.indexOf('_')
    let gbMedia: string
    let gbClick: string

    // 첫 번째 `_`를 기준으로 gbMedia와 gbClick을 분리
    gbMedia = decryptedValue.slice(0, firstUnderscoreIndex)
    gbClick = decryptedValue.slice(firstUnderscoreIndex + 1)
    data.Apclid = gbClick
    data.ChNm = gbMedia
  }

  //affp쿠키가져오기
  const affpCookie = getCookie('affp_data')
  //affp담기
  if (affpCookie) {
    const affpParams = Object.fromEntries(
      affpCookie.split('&').map((entry) => entry.split('=')),
    )
    Object.assign(data, affpParams) //`affp_data` 값을 `data` 객체에 추가
  }
  console.log('data : ', data)
  const validationError = validatePurchaseData(data)
  if (validationError) {
    console.warn(validationError)
    return
  }
  const queryParams = new URLSearchParams(data as any).toString()
  const url = `https://dev-admin.gb-api-dp.com/buylog/cps_log_producer?${queryParams}`
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return response.json()
    })
    .then(() => {
      // ✅ 쿠키 삭제 후 `document.cookie` 확인
      deleteCookies('gb_cps', 'apclid', 'affp_data')

      console.log('삭제 후 쿠키 확인:', document.cookie)
    })
    .catch((error) => {
      console.error('전송 중 오류 발생:', error)
    })
}

// 자동 실행 (사용자가 직접 호출할 필요 없이 작동)
init()

export default { init, getCookieValue, originGetCookieValue, purchase }
