import { Ipurchase } from '.'

const SECRET_KEY = 'ELRyncFxTs0xW4vVppdnYRT5w3ME6y'
function xorEncryptDecrypt(input: string, key: string): string {
  let output = ''
  for (let i = 0; i < input.length; i++) {
    output += String.fromCharCode(
      input.charCodeAt(i) ^ key.charCodeAt(i % key.length),
    )
  }
  return output
}

function base64Encode(input: string): string {
  return btoa(unescape(encodeURIComponent(input))).replace(/=/g, '')
}

function base64Decode(input: string): string {
  return decodeURIComponent(escape(atob(input)))
}

export function validatePurchaseData(data: Ipurchase): string | null {
  const requiredFields: (keyof Ipurchase)[] = [
    'AdNm',
    'ordNo',
    'itemId',
    'itemNm',
    'sellprc',
    'ordAmt',
    'dcAmt',
    'rlordAmt',
    'rlordQty',
    'ordState',
    'ordDate',
  ]

  for (const field of requiredFields) {
    if (data[field] === null || data[field] === undefined) {
      return `❌ 필수 값 누락: ${field}`
    }
  }
  return null
}
export function encrypt(value: string): string {
  return base64Encode(xorEncryptDecrypt(value, SECRET_KEY))
}

export function decrypt(value: string): string {
  return xorEncryptDecrypt(base64Decode(value), SECRET_KEY)
}

export function setCookie(name: string, value: string, hours: number): void {
  const expires = new Date()
  expires.setTime(expires.getTime() + hours * 60 * 60 * 1000)
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/;`
}

export function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
  return match ? match[2] : null
}

function deleteCookie(name: string) {
  console.log(`🛑 쿠키 삭제 시도: ${name}`)

  // 기본 path에서 삭제 시도
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`

  // 루트 도메인 추출 (예: "www.dealpang.com" → "dealpang.com")
  const baseDomain = window.location.hostname.split('.').slice(-2).join('.')

  // 가능한 모든 도메인에서 삭제 시도
  const domains = [
    window.location.hostname, // 현재 도메인
    `.${window.location.hostname}`, // 서브도메인 포함
    `.${baseDomain}`, // 루트 도메인
    `www.${baseDomain}`, // www 포함
  ]

  domains.forEach((domain) => {
    document.cookie = `${name}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
  })

  console.log(`✅ ${name} 쿠키 삭제 완료`)
}

// ✅ 여러 개의 쿠키 삭제 함수
export function deleteCookies(...names: string[]) {
  names.forEach(deleteCookie)
}
